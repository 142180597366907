import { Link } from 'gatsby'
import { fadeIn } from 'react-animations'

import colors from '../../../styles/colors'
import { device } from '../../../styles/media'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import styled, { css, keyframes } from 'styled-components'

const fading = keyframes`${fadeIn}`

export const StyledContainer = styled(Container)`
  animation: 4s ${fading};
  padding-bottom: 100px;
  padding-top: 100px;
  text-align: center;

  @media ${device.xs} {
    height: auto;
    min-height: 450px;
  }
`

export const StyledSection = styled.div`
  background: #212225;
  min-height: 250px;

  @media ${device.tablet} {
    min-height: 560px;
  }

  @media ${device.mobile} {
    min-height: 1200px;
  }
`

export const StyledGrid = styled(Grid)`
  text-align: center;
  height: 100%;
  width: 100%;
  margin: 0;
`

export const StyledP = styled.p`
  margin-top: 90px;
  color: ${colors.secondary};
  font-weight: bold;

  @media ${device.mobile} {
    margin-top: 3rem;
  }
`

export const StyledH2 = styled.h2`
  font-size: 2.5rem;
  padding-bottom: 30px;
  color: ${colors.textDark};
  font-weight: 400;

  @media ${device.mobile} {
    margin-bottom: 0;
  }
`

// This variable kinda sucks
export const StyledH2_Clients = styled.h2`
  color: ${colors.textLight};
  font-size: 2.5rem;
  font-weight: 400;
  padding: 1em 0;
  text-align: center;

  @media ${device.mobile} {
    margin-bottom: 0;
  }
`

export const StyledBodyP = styled.p`
  color: ${colors.textMedium};
  padding: 0 7rem;
  text-align: left;

  @media ${device.mobile} {
    margin-top: 0;
    padding-left: 3rem;
    padding-right: 3rem;
  }
`

// This variable name kinda sucks
export const StyledBodyP_Clients = styled.p`
  color: ${colors.textLight};
  padding: 0 1em;
  text-align: left;

  @media ${device.mobile} {
    margin-top: 0;
    padding-left: 3rem;
    padding-right: 3rem;
  }
`

export const StyledLinks = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

export const StyledLink = styled(Link)`
    background-color: ${colors.secondary};
    border-radius: 25px;
    color: white;
    display: inline-block;
    margin: auto 0.5rem;
    padding 1.1rem 2.0rem;
    text-decoration: none;
    transition: 0.15s;

    :hover {
      color: black;
      background-color: white;
      border: solid 1px black;
    }

    ${props =>
      props.primary &&
      css`
        background-color: ${colors.primaryLight};
        color: ${colors.textLight};
        border: 1px solid transparent;

        :hover {
          color: white;
          background-color: ${colors.primaryDark};
          border: 1px solid ${colors.primaryDark};
        }
      `}

    ${props =>
      props.stroked &&
      css`
        background-color: transparent;
        color: ${colors.textDark};
        border: 1px solid ${colors.textDark};

        :hover {
          color: white;
          background-color: ${colors.textMedium};
          border: 1px solid ${colors.textMedium};
        }
      `}
`

export const StyledLogo = styled.img`
  height: 100%;
  width: 100%;
  max-width: 300px;
`
