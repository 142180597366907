import { Link } from 'gatsby'

import colors from '../../../styles/colors'
import { device } from '../../../styles/media'

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import styled, { css } from 'styled-components'

export const MainShape = styled(Grid)`
  background-image: linear-gradient(${colors.primaryLight}, ${colors.primaryDark});
  height: 800px;
  margin: 0;
  width: 100vw;

  @media ${device.tablet} {
    height: 90vh;
  }

  @media ${device.mobile} {
    height: 90vh;
  }
`

export const StyledContainer = styled(Container)`
  color: white;
  text-align: justify;
  height: 100%;
  padding-bottom: 5rem;
  padding-left: 100px;
  padding-top: 9rem;

  @media ${device.tablet} {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media ${device.mobile} {
    padding-left: 0;
    padding-right: 0;
    padding-top: 7rem;
    text-align: center;
  }
`

export const StyledH1 = styled.h1`
  font-size: 6rem;
  margin: 13px auto;

  @media ${device.laptop} {
    font-size: 4.5rem;
  }

  @media ${device.mobile} {
    font-size: 3rem;
  }
`

export const StyledGrid = styled(Grid)`
  width: 100%;

  @media ${device.mobile} {
    margin: 0;
  }
`

export const StyledGridItemLogo = styled(Grid)`
  align-items: center;
  display: flex;
  justify-content: center;
  height: auto;

  @media ${device.laptop} {
    display: none;
  }
`

export const StyledLogo = styled.img`
  width: 300px;
`

export const StyledGridItem = styled(Grid)`
  height: auto;

  @media ${device.tablet} {
    padding-top: 0 !important;
  }

  ${props =>
    props.image &&
    css`
      @media ${device.mobile} {
        display: none;
      }
    `}
`

export const StyledP = styled.p`
  color: ${colors.textLight};
  font-size: 21px;
`

export const StyledLinks = styled.div`
    margin-top: 4.0rem;

    @media ${device.tablet} {
      margin-top 2rem;
    }
`

export const StyledLink = styled(Link)`
  background-color: ${colors.primaryLight}
  border-radius: 25px;
  color: ${colors.textDark};
  display: inline-block;
  margin: auto 0.5rem;
  padding 1.1rem 2.0rem;
  text-decoration: none;
  transition: 0.2s;

  :hover {
    color: white;
    background-color: ${colors.primaryLight}
  }

  ${props =>
    props.secondary &&
    css`
      background-color: white;
      border-radius: 25px;
      color: ${colors.textDark};

      :hover {
        background-color: ${colors.primaryLight};
      }
    `}
`

export const StyledCta = styled.a`
  background-color: white;
  border-radius: 25px;
  color: ${colors.textDark};
  display: inline-block;
  margin: none;
  padding 1.1rem 2.0rem;
  text-decoration: none;
  transition: 0.2s;

  :hover {
    color: white;
    background-color: ${colors.primaryLight}
  }
`

export const StyledImg = styled.img`
  height: 100%;
  min-width: 312px;
  width: 100%;
`
