import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SEO from '../components/seo'

import OurServices from '../components/Home/OurServices/OurServices'
import ContactUs from '../components/Home/ContactUs/ContactUs'
import Main from '../components/Home/Main/Main'

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />

      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="We build custom software that is tailored to your unique needs. Let's build software that works on your business as hard as you do."
        />
        <title>LaMar Software</title>
        <link rel="canonical" href="https://lamarsoftware.io" />
      </Helmet>

      <Main />
      <OurServices />
      <ContactUs />
    </Layout>
  )
}

export default IndexPage
