import colors from '../../../styles/colors'
import { device } from '../../../styles/media'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import styled, { css } from 'styled-components'

export const StyledBodyP = styled.p`
  color: ${colors.textMedium};
  font-size: 1em;
  min-width: 0;
`
export const StyledIcon = styled(FontAwesomeIcon)`
  color: ${colors.secondary};
  font-size: 4rem;

  @media ${device.tablet} {
    padding-top: 0.4rem;
  }
`
export const StyledContainer = styled(Container)`
  color: ${colors.textDark};
  padding-bottom: 100px;
  padding-top: 100px;
  text-align: center;
  width: 100%;
`

export const StyledGrid = styled(Grid)`
  height: 60%;
  width: 100%;
`

export const StyledGridItem = styled(Grid)`
    height: 35%;
    padding: 1rem;

    ${props =>
      props.upper &&
      css`
        padding-top: 10%;
      `}

    ${props =>
      props.lower &&
      css`
        height: 65%;
      `}

    color: ${colors.textDark};

    @media ${device.tablet}{
      padding-top: 0;
    }

    @media ${device.mobile}{
      ${props =>
        props.upper &&
        css`
          padding: 0 0 1rem 0;
        `}
    }

    width: 100%;
`
export const StyledH2 = styled.h2`
  font-size: 2.5rem;
  font-weight: 400;
  padding-bottom: 30px;

  @media ${device.mobile} {
    margin-bottom: 0;
    padding-bottom: 1rem;
  }
`
export const StyledP = styled.p`
  margin-top: 90px;
  font-weight: bold;
  font-size: 1.3rem;
  color: ${colors.secondary};

  @media ${device.mobile} {
    margin-top: 2rem;
  }
`

export const StyledPaper = styled(Paper)`
  height: 100%;
`

export const StyledTitle = styled.h2`
  margin-top: 0;
`
