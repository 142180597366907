import React from 'react'

import { StyledBodyP, StyledContainer, StyledGrid, StyledGridItem, StyledH2, StyledTitle } from './OurServices.style'

import Grid from '@material-ui/core/Grid'

const OurServices = () => {
  const services = [
    {
      title: 'Custom Web Apps',
      content: 'We prioritize performance and reliability to build blazing-fast platforms on the web.',
    },
    {
      title: 'Enterprise Software',
      content: 'We can build or extend an existing enterprise product your organization currently uses.',
    },
    {
      title: 'Integrations',
      content: 'Already use other third-party apps? Integrations work is out specialty.',
    },
  ]

  const renderServices = services.map((service, i) => {
    return (
      <Grid item lg={4} md={12} sm={12} xs={12} key={i}>
        <StyledGridItem item>
          <StyledTitle>{service.title}</StyledTitle>
          <StyledBodyP>{service.content}</StyledBodyP>
        </StyledGridItem>
      </Grid>
    )
  })
  return (
    <StyledContainer>
      <StyledH2>Our Services</StyledH2>
      <StyledGrid container spacing={3}>
        {renderServices}
      </StyledGrid>
    </StyledContainer>
  )
}

export default OurServices
