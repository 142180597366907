import { Link } from 'gatsby'

import React from 'react'

import logo1 from '../../../images/logo_agproz.png'
import logo2 from '../../../images/logo_qcify.png'
import logo3 from '../../../images/logo_langers.png'
import logo4 from '../../../images/logo_varsity.png'
import logo5 from '../../../images/logo_wf.png'

import {
  StyledSection,
  StyledGrid,
  StyledContainer,
  StyledH2,
  StyledH2_Clients,
  StyledBodyP,
  StyledBodyP_Clients,
  StyledLinks,
  StyledLink,
  StyledLogo,
} from './ContactUs.style'

import Grid from '@material-ui/core/Grid'

const HomeContactUs = () => {
  return (
    <React.Fragment>
      <StyledSection>
        <StyledH2_Clients>Our Success Stories</StyledH2_Clients>
        <StyledGrid container justify="center" alignItems="center" spacing={3}>
          <Grid item md sm={6} xs={12}>
            <Link to="https://app.agproz.com" target="_blank">
              <StyledLogo src={logo1} alt="AgProz logo" />
            </Link>

            <StyledBodyP_Clients>
              We created web-based agriculture sales platform that aggregated farms and crops.
            </StyledBodyP_Clients>
          </Grid>

          <Grid item md sm={6} xs={12}>
            <Link to="https://www.qcify.com" target="_blank">
              <StyledLogo src={logo2} alt="Qcify logo" />
            </Link>

            <StyledBodyP_Clients>
              We prototyped a desktop interface for AI-powered almond-sorting machines.
            </StyledBodyP_Clients>
          </Grid>

          <Grid item md sm={6} xs={12}>
            <Link to="https://langers.com" target="_blank">
              <StyledLogo src={logo3} alt="Langers Juice logo" />
            </Link>

            <StyledBodyP_Clients>
              We built a logistics platform for trucking company networks that deliver Langer products throughout the
              nation.
            </StyledBodyP_Clients>
          </Grid>

          <Grid item md sm={6} xs={12}>
            <Link to="https://www.varsitytech.com" target="_blank">
              <StyledLogo src={logo4} alt="Varsity Technologies logo" />
            </Link>

            <StyledBodyP_Clients>
              We partnered with Varsity to create a suite of applications for non-profits and educational institutions.
            </StyledBodyP_Clients>
          </Grid>

          <Grid item md sm={6} xs={12}>
            <Link to="https://www.stanworkforce.com" target="_blank">
              <StyledLogo src={logo5} alt="Workforce Development logo" />
            </Link>

            <StyledBodyP_Clients>
              We built a website for users to view resources offered by Workforce Development.
            </StyledBodyP_Clients>
          </Grid>
        </StyledGrid>
      </StyledSection>

      <StyledContainer maxWidth="md">
        <StyledH2>Passion Comes First</StyledH2>
        <StyledBodyP>
          Crafting blazing fast, responsive, beautiful websites — while expressing your brand clearly through all
          aspects of design — is second nature. It's what we do. And we love what we do.
        </StyledBodyP>

        <StyledLinks>
          <StyledLink to="/about" stroked>
            About Us
          </StyledLink>
          <StyledLink to="/contact" primary>
            Contact Us
          </StyledLink>
        </StyledLinks>
      </StyledContainer>
    </React.Fragment>
  )
}
export default HomeContactUs
