import { graphql, useStaticQuery, Link } from 'gatsby'
import React from 'react'

import Grid from '@material-ui/core/Grid'

import {
  MainShape,
  StyledContainer,
  StyledGrid,
  StyledGridItem,
  StyledH1,
  StyledP,
  StyledLinks,
  StyledLink,
  StyledCta,
} from './Main.style'

const Main = () => {
  setTimeout(() => {
    console.log(`Hi curious developer! This website was built in Gatsby.\n\n
    ░░░░░░░░▀▀▀██████▄▄▄
    ░░░░░░▄▄▄▄▄░░█████████▄ 
    ░░░░░▀▀▀▀█████▌░▀▐▄░▀▐█ 
    ░░░▀▀█████▄▄░▀██████▄██ 
    ░░░▀▄▄▄▄▄░░▀▀█▄▀█════█▀
    ░░░░░░░░▀▀▀▄░░▀▀███░▀░░░░░░▄▄
    ░░░░░▄███▀▀██▄████████▄░▄▀▀▀██▌
    ░░░██▀▄▄▄██▀▄███▀░▀▀████░░░░░▀█▄
    ▄▀▀▀▄██▄▀▀▌████▒▒▒▒▒▒███░░░░▌▄▄▀
    ▌░░░░▐▀████▐███▒▒▒▒▒▐██▌
    ▀▄░░▄▀░░░▀▀████▒▒▒▒▄██▀
    ░░▀▀░░░░░░▀▀█████████▀
    ░░░░░░░░▄▄██▀██████▀█
    ░░░░░░▄██▀░░░░░▀▀▀░░█
    ░░░░░▄█░░░░░░░░░░░░░▐▌
    ░▄▄▄▄█▌░░░░░░░░░░░░░░▀█▄▄▄▄▀▀▄
    ▌░░░░░▐░░░░░░░░░░░░░░░░▀▀▄▄▄▀
    `)
  }, 750)

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Grid container>
      <MainShape item>
        <StyledContainer>
          <StyledGrid container spacing={10}>
            <StyledGridItem item sm={7} xs={12}>
              <StyledH1>{data.site.siteMetadata.title}</StyledH1>
              <StyledP>Freedom from the software status quo.</StyledP>

              <StyledP>
                We build custom business software that is tailored to meet your unique needs. Let's build software that
                works on your business as hard as you do.
              </StyledP>

              <StyledLink secondary to="/contact">
                Contact Us Now
              </StyledLink>
            </StyledGridItem>
          </StyledGrid>
        </StyledContainer>
      </MainShape>
    </Grid>
  )
}

export default Main
